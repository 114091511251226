import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

import { Location, ScoreCardCategory, ScoreCardGroup, ScoreCardResponse } from "@/interfaces";
import { getLads, getScorecards } from "@/services/ApiService";
import { LocationSelectBar, UnderlineHeader, ButtonIcon, Tooltip } from "@/components/atoms";
import { ScorecardGroupCard } from "@/components/molecules";


type ScorecardOverlayProps = {}

export const ScorecardOverlay: React.FC<ScorecardOverlayProps> = () => {

  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [locations, setLocations] = useState<Array<Location>>([]);

  const [selectedLocations, setSelectedLocations] = useState<Array<Location | null>>([null]);
  const [numLocations, setNumLocations] = useState<number>(1);
  const [confirmedLocations, setConfirmedLocations] = useState<Array<Location>>([]);
  const [confirmed, setConfirmed] = useState<boolean>(false);

  const [scorecardResponse, setScorecardResponse] = useState<ScoreCardResponse[]>([]);
  const [scorecardData, setScorecardData] = useState<ScoreCardGroup[][]>([]);

  const [includeLondon, setIncludeLondon] = useState<boolean>(true);

  const allColors = ['#24d4ff', '#9a5dff', '#e6f0ff', '#f78cdf']
  const groups = ['Group Strategic Priorities', 'Strategic Sectors', 'Hard', 'Soft'];

  useEffect(() => {
    if (scorecardResponse && scorecardResponse.length > 0) {
      includeLondon ?
        setScorecardData(scorecardResponse.map(x => x.scores))
        : setScorecardData(scorecardResponse.map(x => x.scores_excl));
    }

  }, [scorecardResponse, includeLondon]);

  useEffect(() => {
    const fetchData = async (locations: Array<Location>) => {
      setLoading(true);
      const accessToken = await getAccessTokenSilently()
      const response = await getScorecards(locations.map(location => location.geo_id), accessToken);
      if (response) {
        setScorecardResponse(locations.map((x: Location) => response.find((y: ScoreCardResponse) => y.geo_id === x.geo_id)));
      }
    };
    confirmed && confirmedLocations.length > 0 && fetchData(confirmedLocations).then(() => setLoading(false));
  }, [confirmed])


  const getGroupNameScores = (scores: Array<ScoreCardGroup>, name: string): Array<ScoreCardCategory> => {
    if (scores.length > 0) {
      return scores.filter(x => x.name === name)[0].scores || [];
    } else {
      return []
    }
  }

  const getGroupScore = (scores: Array<ScoreCardGroup>, name: string): ScoreCardGroup | undefined => {
    if (scores.length > 0) {
      return scores.filter(x => x.name === name)[0] || undefined
    } else {
      return undefined
    }
  }

  useEffect(() => {
    const fetchLocations = async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await getLads(accessToken);
      setLocations(response);
    }
    fetchLocations().then();
  }, []);

  const onSelectLocation = (location: Location, id: number) => {
    setConfirmed(false);
    setSelectedLocations((prevState: Array<Location | null>) => {
      const newState = [...prevState];
      newState[id] = location;
      return newState;
    })
  }

  const onAddLocation = () => {
    setConfirmed(false);
    setSelectedLocations((prevState: Array<Location | null>) => [...prevState, null]);
    setNumLocations((prevState: number) => prevState + 1)
  }

  const onRemoveLocation = (idx: number) => {
    setConfirmed(false);
    setSelectedLocations((prevState: Array<Location | null>) => {
      return prevState.slice(0, idx).concat(prevState.slice(idx + 1))
    })
    setNumLocations((prevState: number) => prevState - 1)
  }

  const onConfirmLocations = () => {
    setSelectedLocations((prevState: Array<Location | null>) => prevState.filter(x => x !== null));
    setConfirmedLocations(selectedLocations.filter((x: Location | null): x is Location => x !== null));
    setNumLocations(selectedLocations.filter(x => x !== null).length);
    setConfirmed(true);
  }

  const handleLondonToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeLondon(event.target.checked);
  };

  return (
    <Box width="100%" py={4} px="5%" sx={{ backgroundColor: theme.palette.background.paper, overflow: 'auto'}}>
      <Grid container spacing={2} >
        <Grid item xs={12}>
          <Typography variant="h5">Location Scorecard</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Select up to 4 locations to view on the scorecard,
            click the confirm button to load the scorecard when ready.
          </Typography>
        </Grid>
        <Grid item container xs={12} spacing={2} alignItems="center">
          <>
            {[...Array(numLocations).keys()].map((idx) => (
              <Grid item key={`location-search-${idx}`}>
                <LocationSelectBar
                  options={locations}
                  selectedLocation={selectedLocations[idx]}
                  onSelect={(location: Location) => onSelectLocation(location, idx)}
                  onRemove={(idx > 0 || numLocations > 1) ? () => onRemoveLocation(idx) : undefined}
                  color="info"
                  locationColor={allColors[idx]}
                />
              </Grid>
            ))}
            <Grid item>
              <Stack direction="row" spacing={1}>
                {numLocations <= 3 &&
                  <ButtonIcon
                    onClick={onAddLocation}
                    color="primary"
                    size={20}
                    Icon={AddIcon}
                    tooltip="Add Location"
                  />
                }
                {(selectedLocations.length > 0 && selectedLocations[0] !== null && !confirmed) &&
                  <ButtonIcon
                  onClick={onConfirmLocations}
                  color="primary"
                  size={20}
                  Icon={CheckIcon}
                  tooltip="Confirm Locations"
                />
                }
              </Stack>
            </Grid>
          </>
        </Grid>
        <Grid item xs={12}>
          <FormGroup sx={{ p: 1 }}>
            <FormControlLabel control={<Switch checked={includeLondon} onChange={handleLondonToggle} size="small" color="default"/>} label="Include London" />
          </FormGroup>
        </Grid>
        {scorecardData.length > 0 &&
          <Grid item container xs={12} spacing={1}>
            {groups.map((group) => (
              <Grid item>
                <ScorecardGroupCard
                  group={group}
                  locations={confirmedLocations}
                  data={scorecardData.map(x => getGroupNameScores(x, group))}
                  groupScores={scorecardData.map(x => getGroupScore(x, group))}
                  colors={allColors.slice(0, scorecardData.length)}
                />
              </Grid>
            ))}
          </Grid>
        }
      </Grid>
    </Box>
  );
};
