import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { formatScore } from "@/utils/common";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";

type ScorecardScoreProps = {
  name: string,
  scores: number[],
  scoreColors: string[],
  colors: string[]
  stretch?: boolean
};

export const ScorecardScore: React.FC<ScorecardScoreProps> = ({
  name,
  scores,
  scoreColors,
  colors,
  stretch
}) => {

  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent={stretch ? 'space-between': 'normal'}>
      <Typography variant="body1" mr={1}>{name}</Typography>
      <Stack direction="row" spacing={1}>
        {name !== 'Opportunity' &&
          scores.map((score, idx) =>
            <Stack spacing={1} alignItems="center" width={60}>
              <Chip
                size="small"
                label={formatScore(score, 'pct_score')}
                sx={{borderRadius: '6px', minWidth: '48px', backgroundColor: scoreColors[idx], color: theme.palette.background.default}}
              />
              <Box sx={{ height: '4px', width: '80%', backgroundColor: colors[idx] }}/>
            </Stack>
          )
        }
      </Stack>
    </Box>
  )
}
