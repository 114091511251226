import React, { useState } from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { ScoreCardCategory, ScoreCardScore } from "@/interfaces";
import { Tooltip } from '@/components/atoms';
import { formatPercentile, formatScore, toColor } from "@/utils/common";
import Chip from "@mui/material/Chip";
import { useTheme, darken } from "@mui/material/styles";

type ScorecardCardProps = {
  category: string;
  scores: ScoreCardCategory[];
  startExpanded: boolean;
};

export const ScorecardCard: React.FC<ScorecardCardProps> = (
  {
    category,
    scores,
    startExpanded
  }
) => {

  const theme = useTheme();
  const [subExpand, setSubExpand] = useState<boolean>(startExpanded);

  const names = scores[0].scores.map(x => x.feature_name);
  const descs = scores[0].scores.map(x => x.description);

  const onExpand = () => {
    setSubExpand(!subExpand);
  }

  const getScore = (category: ScoreCardCategory, value: string): ScoreCardScore => {
    return category.scores.filter(x => x.feature_name === value)[0];
  }

  return (
    <Card
      elevation={4}
      sx={{
        width: '100%'
      }}
    >
      <CardActionArea onClick={onExpand}>
        <CardHeader
          disableTypography
          title={
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Typography>{category}</Typography>
              <Stack direction="row" spacing={1}>
                {scores.map((categoryScore) => (
                  <Box width="60px" display="flex" alignItems="center">
                    <Chip
                      size="small"
                      label={formatScore(categoryScore.percentile_wt, 'pct_score')}
                      sx={{borderRadius: '6px', minWidth: '48px', backgroundColor: toColor(categoryScore.percentile_wt), color: theme.palette.background.default}}
                    />
                  </Box>
                ))}
              </Stack>
            </Box>
          }
          sx={{
            p: 1,
            pr: 0
          }}
        />
      </CardActionArea>
      {subExpand &&
        <CardContent sx={{ p: 1, '&:last-child': {pb: 1} }}>
          {names.map((name, idx) => (
            <Box key={`score-card-${idx}`} display="flex" flexDirection="row" justifyContent="space-between">
              <Tooltip title={descs[idx]} placement="left">
                <Typography variant="body2" sx={{fontSize: '12px'}}>{name}</Typography>
              </Tooltip>
              <Stack direction="row" spacing={1}>
                {scores.map((score) => {
                  const value = getScore(score, name);
                  return (
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end" width="60px" pr="4px">
                      <Typography variant="body2" sx={{fontSize: '12px'}}>{value ? formatScore(value.value, value.unit) : 'NA'}</Typography>
                      <Tooltip title={value ? formatPercentile(value.percentile_wt) : 'NA'} placement="right">
                        <Box sx={{ ml: 1, height: '12px', width: '12px', backgroundColor: toColor(value ? value.percentile_wt : null) }} />
                      </Tooltip>
                    </Box>
                  )})}
              </Stack>
            </Box>
          ))}
        </CardContent>
      }
    </Card>
  );
}
