import React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import { ScoreCardCategory, ScoreCardGroup, Location } from "@/interfaces";
import { RadarChart, UnderlineText, ScorecardScore } from "@/components/atoms";
import { useTheme, lighten } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { ScorecardCard } from "@/components/molecules";
import { abbreviateLocation, toColor } from "@/utils/common";

type ScorecardGroupCardProps = {
  group: string;
  locations: Location[];
  data: ScoreCardCategory[][];
  groupScores: Array<ScoreCardGroup | undefined>;
  colors: string[];
};

export const ScorecardGroupCard: React.FC<ScorecardGroupCardProps> = (
  {
    group,
    locations,
    data,
    groupScores,
    colors
  }
) => {

  const theme = useTheme();

  const categories = data[0].map(x => x.name);

  const getCategoryScores = (scores: Array<ScoreCardCategory>, category: string): ScoreCardCategory => {
    return scores.filter(x => x.name === category)[0];
  }

  return (
    <Card elevation={0} sx={{ width: `calc(30px + 220px + (60px * ${data.length}))` }}>
      <CardHeader
        disableTypography
        title={
          <ScorecardScore
            name={group}
            scores={groupScores.map(x => x?.percentile_wt || 0)}
            scoreColors={groupScores.map(x => toColor(x?.percentile_wt || 0))}
            colors={colors}
            stretch={true}
          />
        }
        sx={{ backgroundColor: lighten(theme.palette.background.default, 0.1), py: 2, pl: 2, pr: "14px" }}
      />
      <CardContent sx={{ p: 1, borderWidth: '1px', borderStyle: 'solid', borderColor: lighten(theme.palette.background.default, 0.1) }}>
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RadarChart
                values={data.map(x => x.flat().map(y => y.percentile_wt))}
                labels={data[0].flat().map(x => x.name)}
                height={264}
                padding={0}
                circular={false}
              />
            </Grid>
            <Grid item xs={12} display="flex" flexDirection="row" justifyContent="flex-end">
              <Stack direction="row" spacing={1} pr="6px">
                {locations.map((location, idx) => (
                  <UnderlineText
                    text={abbreviateLocation(location.geo_name)}
                    color={colors[idx]}
                    variant="overline"
                    textProps={{noWrap: true, align: "center", sx: {width: '60px'} }}
                  />
                ))}
              </Stack>
            </Grid>
            {categories.map((category, categoryIdx) => (
              <Grid item xs={12} key={`score-card-${categoryIdx}`}>
                <ScorecardCard
                  category={category}
                  scores={data.map(x => getCategoryScores(x, category))}
                  startExpanded={true}
                />
              </Grid>
            ))}
          </Grid>
        </>
      </CardContent>
    </Card>
  );
}
