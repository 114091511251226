import React, { useState } from 'react';
import Draggable from 'react-draggable';

import { useTheme } from "@mui/material/styles";
import Paper, { PaperProps } from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import CloseIcon from '@mui/icons-material/Close';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';

import { SimplePoint, SimpleScoreCard, SimpleScoreCardCategory, SimpleScoreCardGroup } from "@/interfaces";
import { RadarChart, Tooltip } from "@/components/atoms";
import { SimpleFactCard, SimpleScorecardCard, SimpleScorecardPanel } from '@/components/molecules';
import { toTopFacts } from '@/utils/facts';
import { getIconFromName } from '@/utils/icons';
import { toClean, parseValue, formatScore, toColor, convertValue } from "@/utils/common";

function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#simple-point-fact-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type SimplePointFactDialogProps = {
  point: SimplePoint;
  onClose: () => void;
};

const CLASS_COLORS: { [key: string]: string } = {
  'A': '#81c695',
  'B1': '#e5ffac',
  'B2': '#FBE566',
  'C': '#F3546D',
}

const CLASS_TEXT_COLORS: { [key: string]: string } = {
  'A': '#212b3a',
  'B1': '#212b3a',
  'B2': '#212b3a',
  'C': '#212b3a',
}


export const SimplePointFactDialog: React.FC<SimplePointFactDialogProps> = ({
    point,
    onClose,
  }) => {

  const theme = useTheme();

  const data = point.extra_data && JSON.parse(point.extra_data)

  const basicData = Object.keys(data).includes('Overview') && Object.keys(data['Overview']).length === 1

  return (
    <DraggablePaper
      elevation={2}
      sx={{
        position: "fixed",
        bottom: 16,
        left: 16,
        minWidth: '300px',
        height: basicData ? null : '300px',
        // overflowY: 'hidden',
        borderRadius: '6px',
        zIndex: 10
    }}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          overflow: 'hidden'
        }}
      >
        <CardHeader
          id='simple-point-fact-dialog'
          disableTypography
          title={
            <Box display="flex" flexDirection="column"  alignItems="flex-start" width="100%">
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
                <Typography variant="body1">
                  {point.name}
                </Typography>
                <IconButton onClick={onClose}>
                  <CloseIcon fontSize="small"/>
                </IconButton>
              </Box>
              <Stack direction="row" spacing={1}>
                <Chip
                  label={point.class}
                  size="small"
                  sx={{
                    borderRadius: '4px',
                    mr: 1,
                  }}/>
                {point.type !== 'All' &&
                  <Chip
                    label={point.type}
                    size="small"
                    sx={{
                      borderRadius: '4px',
                      mr: 1,
                    }}/>
                }
              </Stack>
            </Box>
          }
          sx={{ pt: 1, pb: basicData ? 2 : 1 }}
        />
        {!basicData &&
          <CardContent sx={{ mb: 2, overflowY: 'auto'}} >
            {Object.keys(data).map((group) => {
              const groupData = data[group];
              return (
                <Box key={group} pb={2}>
                  <Typography
                    variant='body2'
                    color='primary'
                  >
                    {group}
                  </Typography>
                  <Table size='small' padding='none'>
                    <TableBody>
                      {Object.keys(groupData).map((value) => (
                        <TableRow>
                          <TableCell sx={{ fontWeight: 400, fontSize: '12px', color: theme.palette.text.secondary, width: '70%', pt: 1, pb: 0.5 }}>{toClean(value)}</TableCell>
                          <TableCell align="right" sx={{ fontWeight: 400, fontSize: '12px', color: theme.palette.text.secondary, width: '20%', pt: 1, pb: 0.5}}>{convertValue(groupData[value], group)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              )})}
          </CardContent>
        }
      </Card>
    </DraggablePaper>
  );
}
